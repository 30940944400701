import { combineReducers } from 'redux'
import { reducer as permissions } from 'react-redux-permissions'

import user from './user'

const rootReducer = combineReducers({
    permissions,
    user
})

export default rootReducer
