import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'

import asyncComponent from '../components/async'
import NavigationContainer from '../containers/navigation-container'

const { bool, shape } = PropTypes

const AsyncSignInContainer = asyncComponent(() =>
    import('../containers/sign-in-container')
)
const AsyncHome = asyncComponent(() => import('../components/home'))
const AsyncUsers = asyncComponent(() => import('../containers/users'))
const AsyncUserInfo = asyncComponent(() => import('../containers/user-info'))
const AsyncUserCreate = asyncComponent(() =>
    import('../containers/user-create')
)
const AsyncUserCreateTestUsers = asyncComponent(() =>
    import('../containers/user-create-test-users-container')
)
const AsyncUserPasswordReset = asyncComponent(() =>
    import('../containers/user-password-reset-container')
)
const AsyncFirmware = asyncComponent(() => import('../containers/firmware'))
const AsyncTickChart = asyncComponent(() => import('../containers/tick-chart-container'))
const AsyncDevOps = asyncComponent(() => import('../containers/devops-container'))
const AsyncError404 = asyncComponent(() => import('../components/error-404'))

export const Routes = () => (
    <Switch>
        <Route exact path="/" component={AsyncHome} />
        <Route path="/users/create-test-users" component={AsyncUserCreateTestUsers} />
        <Route path="/users/create" component={AsyncUserCreate} />
        <Route path="/users/password-reset" component={AsyncUserPasswordReset} />
        <Route path="/users/:uid/permissions" component={AsyncUserInfo} />
        <Route path="/users/:uid/" component={AsyncUserInfo} />
        <Route exact path="/users" component={AsyncUsers} />
        <Route path="/tick-chart" component={AsyncTickChart} />
        <Route path="/firmware" component={AsyncFirmware} />
        <Route path="/devops" component={AsyncDevOps} />
        <Route component={AsyncError404} />
    </Switch>
)

const MainContainer = props => (
    <Router>
        <Container style={{ padding: '7rem 0 5rem' }}>
            {props.user.isAuthed ? (
                <Fragment>
                    <NavigationContainer />
                    <Routes />
                </Fragment>
            ) : (
                <AsyncSignInContainer />
            )}
        </Container>
    </Router>
)

MainContainer.propTypes = {
    user: shape({
        isAuthed: bool.isRequired
    }).isRequired
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(MainContainer)
