import React, { Component } from 'react'
import { shape, instanceOf } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Navigation from '../components/navigation'
import { handleLogout } from '../services/signin'

class NavigationContainer extends Component {
    static propTypes = {
        user: shape({}).isRequired,
        permissions: instanceOf(Array).isRequired
    }

    handleLogout = () => {
        handleLogout(
            this.props.user,
            this.props.permissions
        )
    }

    render() {
        return (
            <Navigation
                user={this.props.user}
                handleLogout={this.handleLogout}
                permissions={this.props.permissions}
            />
        )
    }
}

const mapStateToProps = state => ({
    user: state.user,
    permissions: state.permissions
})

export default withRouter(
    connect(mapStateToProps)(NavigationContainer)
)
