
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import MainContainer from './containers/main-container'

import store, { persistor } from './store'
import './styles/app.css'

const Application = () => (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <MainContainer store={store} />
        </PersistGate>
    </Provider>
)

export default Application
