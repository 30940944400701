import React from 'react'
import PropTypes from 'prop-types'
import Permissions from 'react-redux-permissions'
import { NavLink, Link } from 'react-router-dom'
import { Container, Icon, Menu, Dropdown } from 'semantic-ui-react'
// import navData from '../data/main-nav'
// import xid from 'xid'

const { func, shape, string } = PropTypes

const Navigation = props => (
    <Menu fixed="top" inverted as="nav" className="primary-nav">
        <Container>
            <Menu.Item as={NavLink} to="/" exact>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 164 164"
                    role="img"
                    aria-labelledby="jh-title"
                    className="header-logo"
                    style={{
                        fill: '#FFFFFF',
                        width: '3rem',
                        height: '3rem'
                    }}
                >
                    <title id="jh-title">Jawbone Health</title>
                    <path d="M40.2 42h9.6v58.2c0 10.3-4.6 17.4-26.3 26.9l-.4-3.1c16.1-10.4 17.1-12.3 17.1-22.5V42zM121.3 42v32.5H86.4V42h-9.6v72h9.6V80.5h34.9V114h9.5V42z" />
                </svg>
            </Menu.Item>
            <Permissions allowed={['jhh_admin']}>
                <Dropdown item text="Users">
                    <Dropdown.Menu className="nav-dropdown">
                        <Dropdown.Item>
                            <Link to="/users">
                                Search Users
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Link to="/users/create">
                                Create New User
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Link to="/users/password-reset">
                                Reset User Password
                            </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Link to="/users/create-test-users">
                                Create Test Users
                            </Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Permissions>
            {/* this stuff isn't going to be a thing for a while  */}
            {/* {navData.map(({ label, id, href, exact = false }) => (
                <Menu.Item
                    exact={exact}
                    as={NavLink}
                    to={href}
                    name={id}
                    content={label}
                    key={xid.generateId()}
                    activeClassName="active"
                />
            ))} */}
            <Permissions allowed={['jhh_datalogist']}>
                <Menu.Item
                    as={NavLink}
                    to="/tick-chart"
                    name="tick-chart"
                >
                    Tick Chart
                </Menu.Item>
            </Permissions>
            <Menu.Menu position="right">
                <Menu.Item name="signin" header>
                    <Icon name="user outline" />
                    <span className="user-name">
                        {props.user.name}
                    </span>
                </Menu.Item>
                <Menu.Item name="signin" className="sign-out-link">
                    <Icon name="sign out" />
                    <span
                        onClick={props.handleLogout}
                        className="sign-out-anchor"
                        href="/signout"
                    >
                        Sign out
                    </span>
                </Menu.Item>
            </Menu.Menu>
        </Container>
    </Menu>
)

Navigation.propTypes = {
    user: shape({
        name: string
    }).isRequired,
    handleLogout: func.isRequired
}

Navigation.defaultProps = {
    user: {
        name: ''
    }
}

export default Navigation
