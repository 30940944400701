import base64 from 'base-64'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { add, remove } from 'react-redux-permissions'

import store from '../store'
import { authUser, unAuthUser } from '../actions/user'

const baseURL = process.env.REACT_APP_USER_API_URL
const { dispatch } = store

const setUserData = (token) => {
    const tokenData = jwtDecode(token)
    const userData = {
        isAuthed: true,
        authTime: Date.now(),
        uid: tokenData.id.uid,
        name: `${tokenData.id.first} ${tokenData.id.last}`,
        exp: tokenData.exp,
        perms: tokenData.prm,
        token: token
    }

    if (
        !userData.perms.includes('jhh_admin') &&
        !userData.perms.includes('jhh_datalogist')
    ) {
        return false
    }

    dispatch(authUser(userData))
    userData.perms.map(perm => dispatch(add(perm)))
    return true
}

const removeUserData = (permissions) => {
    dispatch(unAuthUser())
    permissions.map(perm => dispatch(remove(perm)))
}


export const handleLogin = (creds, errorCallback) => {
    let userCreds = `${creds.username}:${creds.password}`
    userCreds = base64.encode(userCreds)

    const request = new Request(
        `${baseURL}/@me/tokens`,
        {
            method: 'POST',
            headers: new Headers({
                Authorization: `Basic ${userCreds}`
            })
        }
    )

    fetch(request)
        .then(resp => resp.json())
        .then((data) => {
            if (data) {
                if (!setUserData(data.token)) {
                    errorCallback()
                }
            } else {
                errorCallback()
            }
        })
        .catch((error) => {
            errorCallback(error.message)
        })
}

export const handleLogout = (user, permissions) => {
    axios.delete(`${baseURL}/${user.uid}/tokens`, {
        headers: {
            Authorization: `Bearer ${user.token}`
        }
    })
        .then(() => removeUserData(permissions))
        .catch(() => removeUserData(permissions))
}

export default handleLogin
